.skeleton_inner {
  margin-top: 20px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  .skeleton_wrapper {
    padding: 10px 20px;
    display: grid;
    align-items: center;
    grid-gap: 0 10px;
    background: #f2f2f2;
    &.header {
      padding: 20px;
    }
    .skeleton {
      background: #ddd;
      overflow: hidden;
      position: relative;
      &.avatar {
        @include media-max(700px) {
          width: 35px;
          height: 35px;
        }
      }
      &.box {
        width: 20px;
        height: 20px;
        border-radius: 4px;
      }
      &.text {
        width: 100%;
        height: 12px;
        border-radius: 30px;
      }
      &.label {
        width: 100px;
        height: 8px;
        border-radius: 30px;
        margin-bottom: 10px;
      }
      &.title {
        width: 50%;
        height: 20px;
        border-radius: 30px;
      }
      &.cell {
        width: 100%;
        height: 12px;
      }
      &.icon {
        width: 25px;
        height: 25px;
        border-radius: 50%;
      }
      &.input {
        width: 100%;
        height: 38px;
        border-radius: 4px;
      }
      &.button {
        width: 120px;
        height: 38px;
        border-radius: 4px;
      }
      &.switch {
        width: 100%;
        height: 20px;
        border-radius: 30px;
      }
      .shimmer_wrapper {
        @include absolute-full;
        animation: loading 2s infinite;
        .shimmer {
          width: 95%;
          height: 100%;
          background: rgba($color: $color-white, $alpha: 0.2);
          transform: skewX(-20deg);
          box-shadow: 0 0 30px 20px rgba($color: $color-white, $alpha: 0.05);
        }
      }
    }
  }
  &.user_form {
    margin: 0;
    .tabs {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      margin-bottom: 50px;
      .tab {
        width: 100px;
        height: 12px;
        margin-right: 20px;
        border-radius: 30px;
      }
    }
    .row {
      display: grid;
      grid-template-columns: 190px 1fr;
      grid-gap: 0 50px;
      align-items: flex-end;
      @include media-max(1000px) {
        grid-template-columns: 160px 1fr;
        grid-gap: 0 30px;
        align-items: flex-start;
      }
      @include media-max(800px) {
        grid-template-columns: 100%;
        grid-gap: 20px 0;
      }
      .avatar {
        width: 190px;
        height: 190px;
        @include media-max(1000px) {
          width: 160px;
          height: 160px;
        }
      }
      .outer {
        .inputs {
          .input {
            margin-bottom: 20px;
            @include media-max(1000px) {
              margin-bottom: 25px;
            }
          }
        }
        .buttons {
          display: flex;
          align-items: center;
          .button {
            margin-left: 20px;
            @include media-max(1000px) {
              width: 70px;
            }
          }
        }
      }
    }
  }
  &.news_form {
    margin: 0 !important;
    .label {
      margin-top: 20px;
    }
    .textarea {
      height: 170px;
    }
    .dropzone {
      height: 250px;
      margin-bottom: 20px;
    }
    .buttons {
      display: flex;
      align-items: center;
      .button {
        margin-left: 20px;
        @include media-max(1000px) {
          width: 70px;
        }
      }
    }
  }
  &.show_order {
    margin: 0;
    .title {
      margin: 10px 0;
    }
    .item {
      display: grid;
      align-items: center;
      grid-template-columns: 160px 1fr;
      padding: 13px 0;
      @include media-max(700px) {
        grid-template-columns: 130px 1fr;
      }
      @include media-max(500px) {
        grid-template-columns: 120px 1fr;
      }
      .label {
        height: 12px;
        margin: 0;
      }
      .input {
        width: 300px;
        @include media-max(700px) {
          width: 100%;
        }
      }
    }
    &.note {
      width: 320px;
      margin: 0;
      background: $color-white;
      padding: 20px;
      box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
      @include media-max(1000px) {
        width: 100%;
        margin-top: 20px;
      }
      .title {
        margin: 0;
      }
      .text {
        margin: 30px 0 10px 0;
      }
      .textarea {
        width: 100%;
        height: 200px;
        margin: 20px 0;
      }
      .input {
        margin-bottom: 20px;
      }
    }
  }
  &.product_form {
    &.mt-20 {
      .tabs {
        padding-bottom: 30px;
      }
    }
    margin: 0;
    .skeleton_wrapper {
      display: block;
    }
    .tabs {
      display: flex;
      align-items: center;
      padding: 10px 0;
      .tab {
        width: 100px;
        height: 12px;
        border-radius: 30px;
        margin-right: 20px;
        @include media-max(800px) {
          width: 60px;
        }
        @include media-max(500px) {
          width: 40px;
        }
      }
    }
    .inputs_group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px 30px;
      @include media-max(1000px) {
        grid-template-columns: 100%;
        grid-gap: 20px 0;
      }
      .row {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 40px;
        grid-gap: 0 10px;
      }
    }
    .textarea_field {
      margin: 20px 0;
      .textarea {
        width: 100%;
        height: 120px;
        border-radius: 6px;
      }
    }
    .checkbox {
      display: grid;
      align-items: center;
      grid-template-columns: 20px 100px;
      grid-gap: 0 20px;
      margin-bottom: 20px;
      .label {
        margin: 0;
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      .button {
        margin-left: 20px;
      }
    }
  }
  &.categories_form {
    margin: 0;
    &.mt-20 {
      .tabs {
        padding-bottom: 30px;
      }
    }
    .skeleton_wrapper {
      display: block;
    }
    .tabs {
      display: flex;
      align-items: center;
      padding: 10px 0;
      .tab {
        width: 100px;
        height: 12px;
        border-radius: 30px;
        margin-right: 20px;
        @include media-max(800px) {
          width: 60px;
        }
        @include media-max(500px) {
          width: 40px;
        }
      }
    }
    .inputs_group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px 30px;
      @include media-max(1000px) {
        grid-template-columns: 100%;
        grid-gap: 20px 0;
      }
      .row {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 40px;
        grid-gap: 0 10px;
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .button {
        margin-left: 20px;
      }
    }
  }
  &.classic_form {
    margin: 0;
    .dropzone {
      width: 100%;
      height: 200px;
      margin: 10px 0 30px 0;
    }
    .inputs_group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px 30px;
      @include media-max(1000px) {
        grid-template-columns: 100%;
        grid-gap: 20px 0;
      }
      .row {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 40px;
        grid-gap: 0 10px;
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .button {
        margin-left: 20px;
      }
    }
  }
  &.invoices_data {
    margin: 0;
    .item {
      display: grid;
      align-items: center;
      grid-template-columns: 90px 1fr;
      grid-gap: 0 30px;
      padding: 15px 0;
      @include media-max(750px) {
        grid-template-columns: 70px 1fr;
      }
      .label {
        margin: 0;
        width: 100%;
      }
      .input {
        width: 300px;
        @include media-max(500px) {
          width: 100%;
        }
      }
    }
  }
  &.invoices_show {
    margin: 0;
    .title {
      margin: 15px 0;
    }
    .holder {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0 20px;
      @include media-max(1400px) {
        grid-template-columns: 100%;
        grid-gap: 50px 0;
      }
      .item {
        display: grid;
        align-items: center;
        grid-template-columns: 90px 1fr;
        grid-gap: 0 50px;
        padding: 15px 0;
        @include media-max(750px) {
          grid-template-columns: 70px 1fr;
        }
        .label {
          margin: 0;
          width: 100%;
        }
        .input {
          width: 300px;
          @include media-max(500px) {
            width: 100%;
          }
        }
      }
    }
  }
  &.items_product {
    margin: 20px 0;
    .skeleton_wrapper {
      &.body {
        display: grid;
        align-items: center;
        grid-template-columns: 100px 1fr 100px 100px 100px;
        @include media-max(850px) {
          grid-template-columns: 100px 1fr;
          .text:nth-child(3) {
            display: none;
          }
          .text:nth-child(4) {
            display: none;
          }
          .text:nth-child(5) {
            display: none;
          }
        }
        @include media-max(500px) {
          grid-template-columns: 50px 1fr;
        }
        .image {
          width: 100px;
          height: 40px;
          border-radius: 6px;
          @include media-max(500px) {
            width: 100%;
          }
        }
        .column {
          width: 250px;
          @include media-max(500px) {
            width: 100%;
          }
          .title {
            margin: 0 0 10px 0;
          }
        }
      }
    }
  }
}

@keyframes loading {
  from {
    transform: translateX(-150%);
  }
  to {
    transform: translateX(150%);
  }
}

.skeleton_wrapper {
  &.users {
    grid-template-columns: 40px 150px 400px 1fr 25px 25px;
    @include media-max(1700px) {
      grid-template-columns: 40px 100px 200px 1fr 25px 25px;
    }
    @include media-max(900px) {
      grid-template-columns: 40px 60px 100px 1fr 25px 25px;
    }
    @include media-max(700px) {
      grid-template-columns: 35px 1fr 25px 25px;

      .skeleton:nth-child(3) {
        display: none;
      }
      .skeleton:nth-child(4) {
        display: none;
      }
    }
  }
  &.orders {
    padding: 20px;
    @include media-max(1000px) {
      padding: 15px 20px;
    }
    grid-template-columns: 20px 150px 400px 1fr 100px;
    @include media-max(1700px) {
      grid-template-columns: 40px 100px 200px 1fr 70px;
    }
    @include media-max(1000px) {
      grid-template-columns: 20px 150px 1fr;

      .skeleton:nth-child(4) {
        display: none;
      }
      .skeleton:nth-child(5) {
        display: none;
      }
    }
    @include media-max(760px) {
      grid-template-columns: 20px 80px 1fr;
    }
  }
  &.activity_log {
    padding: 6px 20px;
    grid-template-columns: 60px 40px 70px 1fr 100px;
    @include media-max(800px) {
      grid-template-columns: 25px 40px 60px 1fr;
      .skeleton:last-child {
        display: none;
      }
    }
    @include media-max(500px) {
      grid-template-columns: 25px 40px 1fr;
      .skeleton:nth-child(4) {
        display: none;
      }
    }
  }
  &.products {
    grid-template-columns: 20px 200px 1fr 100px 25px 25px 25px;
    @include media-max(1700px) {
      padding: 20px;
    }
    @include media-max(1000px) {
      padding: 20px;
      grid-template-columns: 20px 100px 1fr 25px 25px 25px;
      .skeleton:nth-child(2) {
        display: none;
      }
    }
    @include media-max(500px) {
      grid-template-columns: 20px 100px 1fr;
      .skeleton:nth-child(2) {
        display: none;
      }
      .skeleton:nth-child(5) {
        display: none;
      }
      .skeleton:nth-child(6) {
        display: none;
      }
      .skeleton:nth-child(7) {
        display: none;
      }
    }
  }
  &.linking_product_our {
    grid-template-columns: 100px 200px 1fr 25px 25px 25px;
    @include media-max(500px) {
      grid-template-columns: 50px 1fr 25px 25px 25px;
      .skeleton:nth-child(2) {
        display: none;
      }
    }
  }
  &.categories {
    grid-template-columns: 50px 1fr 100px 100px 25px 25px;
    @include media-max(500px) {
      grid-template-columns: 25px 1fr 25px 25px;
      .skeleton:nth-child(3) {
        display: none;
      }
      .skeleton:nth-child(4) {
        display: none;
      }
    }
  }
  &.linking_categories_our {
    grid-template-columns: 1fr 20px;
  }
  &.web_margins {
    grid-template-columns: 200px 100px 1fr 25px 25px;
    @include media-max(800px) {
      padding: 20px;
      grid-template-columns: 50px 1fr 25px 25px;
      .skeleton:nth-child(1) {
        display: none;
      }
    }
  }
  &.invoices_banks {
    grid-template-columns: 300px 1fr 20px 25px 25px;
    @include media-max(1300px) {
      grid-template-columns: 200px 1fr 20px 25px 25px;
    }
    @include media-max(700px) {
      grid-template-columns: 1fr 20px 25px 25px;
      .skeleton:nth-child(1) {
        display: none;
      }
    }
  }
  &.invoices {
    grid-template-columns: 20px 200px 1fr 100px;
    @include media-max(770px) {
      grid-template-columns: 20px 1fr;
      .skeleton:nth-child(2) {
        display: none;
      }
      .skeleton:nth-child(4) {
        display: none;
      }
    }
  }
  &.invoices_emails {
    grid-template-columns: 1fr 20px 25px 25px;
  }
  &.inline {
    grid-template-columns: 1fr 25px 25px;
  }
}

.linking_categories_screen_outer {
  .skeleton_inner {
    margin-top: 80px;
  }
  @include media-max(500px) {
    .supplier_categories_table .skeleton_inner {
      margin-top: 140px;
    }
  }
}
.product_item_skeleton_wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @include media-max(1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include media-max(768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  .product_item_skeletor {
    margin: 0;
    .skeleton_wrapper {
      display: block;
      padding: 20px;
      transform: scale(0.97);
      border-radius: 6px;
      .title {
        margin: 20px 0;
        width: 80%;
      }
      .photo {
        width: 100%;
        border-radius: 6px;
        height: 200px;
        @include media-max(800px) {
          height: 120px;
        }
      }
      .row {
        @include flex-between;
        margin: 20px 0;
        .price {
          width: 50px;
          height: 30px;
          border-radius: 6px;
          @include media-max(800px) {
            width: 40px;
            height: 22px;
          }
        }
        .thumbnail {
          width: 50px;
          height: 30px;
          border-radius: 6px;
          @include media-max(800px) {
            width: 40px;
            height: 22px;
          }
        }
      }
      .button {
        border-radius: 30px !important;
        width: 100% !important;
        @include media-max(800px) {
          height: 34px !important;
        }
      }
      .favorite {
        width: 25px;
        height: 25px;
        margin-top: 20px;
        margin: 20px auto 0 auto;
        border-radius: 50%;
      }
    }
  }
}
